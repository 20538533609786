<template>
  <div class="view pa24">
    <div class="bgf7fa pa15 mb30">
      <div class="fs8 w600 d-flex justify-content-between mb20 mr20">
        <p>企业名称：</p>
        <p>{{companyInfo.companyName}}</p>
      </div>
      <div class="fs8 w600 d-flex justify-content-between mb20 mr20">
        <p>管理员：</p>
        <p>{{companyInfo.userName}}</p>
      </div>
      <div class="fs8 w600 d-flex justify-content-between mb20 mr20">
        <p>管理员电话：</p>
        <p>{{companyInfo.phone}}</p>
      </div>
      <div class="fs8 w600 d-flex justify-content-between mr20">
        <p>剩余可提现余额：</p>
        <p>{{companyInfo.balance}}元</p>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <el-date-picker
        v-model="pickerValue"
        class="mr10 "
        align="right"
        value-format="yyyy-MM-dd"
        type="daterange"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      />
      <el-button type="primary" @click="queryVisitorsData">搜索</el-button>
    </div>
    <commonTable
      :tableData="tableData"
      :loading="loading"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column
            prop="createdTime"
            align="center"
            label="发生时间"
            show-overflow-tooltip
          />
        <el-table-column prop="visitTime" align="center" label="记录类型">
          <template slot-scope="scope">
            <p class="cblue" v-if="scope.row.changeType == 0">订单销售新增</p>
            <p class="cblue" v-if="scope.row.changeType == 1">佣金扣减</p>
            <p class="cblue" v-if="scope.row.changeType == 2">提现</p>
            <p class="corange" v-if="scope.row.changeType == 3">提现失败退回</p>
          </template>
        </el-table-column>
        <el-table-column prop="beforeBalance" align="center" label="余额变化">
          <template slot-scope="scope">
            {{scope.row.amount > 0 ? '+' : ''}}{{scope.row.amount}}
          </template>
        </el-table-column>
        <el-table-column prop="afterBalance" align="center" label="余额" />
      </template>
    </commonTable>

  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { getDataTimeSec } from "@/utils";
import { queryCompanyStaff } from "@/api/articleManagement";
import { bossSubordinationBalanceDetails } from "@/api/financing";
export default {
  name: "visitorData",
  components: {
    commonTable,
  },
  data() {
    return {
      pickerValue: "", //选择时间
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      currentDateIndex:7,
      dialogTableVisible:false,
      companyInfo:''
    };
  },
  filters: {
    getDataTimeSec(val) {
      return getDataTimeSec(val);
    },
  },
  created() {
    this.companyid = this.$route.query.id
    
    
    
  },
  mounted(){
    this.queryCompanyStaff();
    this.queryVisitorsData();
  },
  methods: {
    async queryCompanyStaff() {
      try {
        const result = await queryCompanyStaff({ companyId: 0 });
        this.staffs = result.data;
      } catch (error) {
        console.log(error);
      }
    },
    async queryVisitorsData() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        companyid:this.companyid,
        startTime: this.pickerValue && `${this.pickerValue[0]} 00:00:00`,
        endTime: this.pickerValue && `${this.pickerValue[1]} 23:59:59`,
      };
      try {
        this.loading = true;
        const result = await bossSubordinationBalanceDetails(data);
        this.loading = false;
        if(result.data.pageInfo){
          const { total, list } = result?.data?.pageInfo;
          this.companyInfo = result?.data?.companyInfo
          this.companyInfo.balance = (this.companyInfo.balance/100).toFixed(2)
          this.tableData = list;
          this.tableData.forEach(item=>{
            item.afterBalance = (item.afterBalance/100).toFixed(2)
            item.beforeBalance = (item.beforeBalance/100).toFixed(2)
          })
          this.total = total;
        }else{
          
          this.tableData = []
        }
        
      } catch (error) {
        this.tableData = []
        this.loading = false;
        console.log(error);
      }
    },
    formatSeconds(value) {
      var theTime = parseInt(value);// 秒
      var theTime1 = 0;// 分
      var theTime2 = 0;// 小时
      if(theTime > 60) {
        theTime1 = parseInt(theTime/60);
        theTime = parseInt(theTime%60);
        if(theTime1 > 60) {
          theTime2 = parseInt(theTime1/60);
          theTime1 = parseInt(theTime1%60);
        }
      }
      var result = ""+parseInt(theTime)+"秒";
      if(theTime1 > 0) {
        result = ""+parseInt(theTime1)+"分"+result;
      }
      if(theTime2 > 0) {
        result = ""+parseInt(theTime2)+"小时"+result;
      }
      return result;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryVisitorsData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryVisitorsData();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>